/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  createParticipantUserEntryApi,
  getParticipantDemoStatusV2Api,
  createParticipantV3Api,
  clientRedirectUpdateApi,
  participantsDemographicsApi,
  getExposeClientParticipantDataApi,
  getAiQuestionApi,
  getAiQuestionReviewApi,
} from 'services/participantService';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const createParticipantUserEntryOnly = createAsyncThunk('paricipant/createParticipantUserEntry', async ({ vendorId, browserInfo, userLandingUrl }, { rejectWithValue }) => {
  try {
    const res = await createParticipantUserEntryApi(vendorId, browserInfo, userLandingUrl);
    return res.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const createParticipantUserEntry = createAsyncThunk('paricipant/getParticipantDemoStatusV2', async ({ vendorId, browserInfo, userLandingUrl }, { rejectWithValue }) => {
  try {
    const res = await getParticipantDemoStatusV2Api(vendorId, browserInfo, userLandingUrl);
    return res.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const participantsDemographics = createAsyncThunk('paricipant/participantsDemographics', async ({ userData, userLandingUrl }, { rejectWithValue }) => {
  try {
    const participantData = JSON.stringify(userData);
    const landingUrlString = userLandingUrl;
    const newUserId = cookies.get('userId');
    const res = await participantsDemographicsApi(participantData, landingUrlString, newUserId);
    return res.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const createParticipant = createAsyncThunk('paricipant/createParticipant', async ({
  userData, userLandingUrl, PID, botDetectionResult,
}, { rejectWithValue }) => {
  try {
    const participantData = JSON.stringify(userData);
    const landingUrlString = userLandingUrl;
    const newUserId = cookies.get('userId');
    const res = await createParticipantV3Api(participantData, landingUrlString, newUserId, PID, botDetectionResult);
    return res.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const updateClientAppRedirect = createAsyncThunk('paricipant/clientRedirectUpdate', async (data, { rejectWithValue }) => {
  try {
    const res = await clientRedirectUpdateApi(data);
    return res.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getParticipantInitialData = createAsyncThunk('paricipant/getExposeClientParticipantData', async (data, { rejectWithValue }) => {
  try {
    const res = await getExposeClientParticipantDataApi(data);
    return res.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getAIQuestion = createAsyncThunk(
  'participant/aiQuestion',
  async (studyId, { rejectWithValue }) => {
    try {
      const res = await getAiQuestionApi(studyId);
      return res.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ success: false, data: [], msg: 'something went wrong' });
    }
  },
);

export const getAiQuestionReviewed = createAsyncThunk(
  'participant/submit-randomquestionres',
  async (data, { rejectWithValue }) => {
    try {
      const res = await getAiQuestionReviewApi(data);
      return res.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ success: false, msg: 'something went wrong' });
    }
  },
);

const createParticipantUserEntrySlice = createSlice({
  name: 'participantSlice',
  initialState: {
    response: '',
    loading: false,
    createParticipantResponse: '',
    getParticipantData: '',
    createParticipantUserEntry,
    createParticipantUserEntryOnly,
    requiredResponses: [],
    aiQuestionData: [],
  },
  extraReducers: {
    [createParticipant.pending]: (state, action) => {
      state.loading = true;
    },
    [createParticipant.fulfilled]: (state, action) => {
      state.loading = false;
      state.createParticipantResponse = action.payload;
    },
    [participantsDemographics.pending]: (state, action) => {
      state.loading = true;
    },
    [participantsDemographics.fulfilled]: (state, action) => {
      state.loading = false;
      state.createParticipantResponse = action.payload;
    },
    [getParticipantInitialData.pending]: (state, action) => {
      state.loading = true;
    },
    [getParticipantInitialData.fulfilled]: (state, action) => {
      state.loading = false;
      state.getParticipantData = action.payload;
      state.requiredResponses = [...state.requiredResponses, action.payload];
    },
    [createParticipantUserEntry.pending]: (state, action) => {
      state.loading = true;
    },
    [createParticipantUserEntry.fulfilled]: (state, action) => {
      state.loading = false;
      state.requiredResponses = [...state.requiredResponses, action.payload];
      state.createParticipantUserEntry = action.payload;
    },
    [createParticipantUserEntryOnly.pending]: (state, action) => {
      state.loading = true;
    },
    [createParticipantUserEntryOnly.fulfilled]: (state, action) => {
      state.loading = false;
      state.requiredResponses = [...state.requiredResponses, action.payload];
      state.createParticipantUserEntryOnly = action.payload;
    },
    [getAIQuestion.pending]: (state) => {
      state.loading = true;
    },
    [getAIQuestion.fulfilled]: (state, action) => {
      state.loading = false;
      state.aiQuestionData = action.payload.data;
    },
    [getAiQuestionReviewed.pending]: (state) => {
      state.loading = true;
    },
    [getAiQuestionReviewed.fulfilled]: (state, action) => {
      state.loading = false;
    },

  },
});

const { reducer } = createParticipantUserEntrySlice;
export default reducer;
